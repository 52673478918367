<template>
  <div class="container-articulos" v-if="items">
    <div class="container-articulo" v-for="i in items" :key="i.key" v-html="i.articulo"></div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "PubArticulosLista",
  props: ["seccion", "subseccion", "publicacion"],

  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("articulos"),
      items: {},
    };
  },
  created() {
    this.ref.where("activo", "==", true).orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      console.log(this.items)
    });
  },
};
</script>

<style lang="scss" scoped>
  .container-articulo {
    margin: 5rem 2rem;

    /deep/ h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 1.5rem 0rem;
    }
    /deep/ h2 {
      font-size: 1.2rem;
      font-weight: 700;
      margin: 1.5rem 0rem;
    }
    /deep/ p {
      font-weight: 300;
      text-align: justify;
    }
  }
</style>