<template>
  <div class="container-fichas" v-if="images">
    <div class="container-foto" v-for="(image, i) in images" :key="image.id">
      <img class="image"  :src="image.url" @click="onClick(i)">
      <span>{{ image.descripcion }}</span>
    </div>
    <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
  </div>
</template>

<script>
import firebase from "../firebase";
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: "PubDocumentosLista",
  props: ["seccion", "subseccion", "publicacion"],
  components: {
    VueGallerySlideshow
  },
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("fotos"),
      images: {},
      index: null
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.images = [];
      querySnapshot.forEach((doc) => {
        this.images.push({
          key: doc.id,
          url: doc.data().url,
          orden: doc.data().orden,
          descripcion: doc.data().descripcion
        });
      });
      //console.log(this.fotos)
    });
  },
  methods: {
    onClick(i) {
      this.index = i;
    }
  }
};
</script>

<style lang="scss" scoped>

  @import "../scss/abstracts/variables";
  .container-foto {
    display: inline-grid;
    margin: 1rem;
  }
  .image {
    width: 250px;
    height: 150px;
    object-fit: cover;
    border: 1px solid $color-text;
    //border-radius: 5px;
  }
  span {
    font-size: 0.9rem;
    font-weight: 300;
  }
  /deep/ .vgs__container{
    height: 75vh !important;
    max-width: 80%;
    margin-top: 1rem;
  }
</style>
