<template>
  <div class="container-documentos" v-if="items">
    <div class="documento" v-for="i in items" :key="i.key">
      <div class="container-links">
        <PubUrlsLista :seccion="seccion" :subseccion="subseccion" :publicacion="publicacion" :documento="i.key"/>
      </div>
      <div class="container-titulo-documento">
        <p><span v-if="i.fecha" class="fecha">{{i.fecha}}.</span>{{i.titulo}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import PubUrlsLista from "./PubUrlsLista.vue"

export default {
  name: "PubDocumentosLista",
  props: ["seccion", "subseccion", "publicacion"],
  components: {
    PubUrlsLista,
  },
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("documentos"),
      items: {},
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          titulo: doc.data().titulo,
          fecha: doc.data().fecha,
          orden: doc.data().orden,
        });
      });
      //console.log(this.items)
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
  .documento {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .container-links {
      width: 100%;
      text-align: center;
      //flex: 0 0 95px;
      //max-width: 95px;
      padding: 0 0.5rem;
      @include media-breakpoint-up(md) {
        width: 90px;
      }
    }
    .container-titulo-documento {
      width: 100%;
      //flex: 0 0 calc(100% - 95px);
      //max-width: calc(100% - 95px);
      padding: 0 0.5rem;
      @include media-breakpoint-up(md) {
        width: calc(100% - 90px);;
      }
      p {
        text-align: justify;
        font-weight: 300;
        font-size: 0.85rem;
        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
      }
      .fecha {
        margin-right: 0.75rem;
        font-size: 0.7rem;
        font-weight: 600;
        color: $color-03;
        @include media-breakpoint-up(md) {
          font-size: 0.85rem;
        }
      }
    } 
    
  }
</style>
