<template>
  <div class="container-fichas" v-if="items">
    <ul class="lista-integrantes">
      <li v-for="i in items" :key="i.key">
        <a :href="i.url" target="_blank">
          <img class="img-integrante" :src="i.foto" :alt="i.nomobre">
        </a>
        <p class="nombre">Dip. {{ i. nombre }}</p>
        <p class="apellidos">{{ i.apellidos }}</p>
        <p class="cargo">{{ i.cargo }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "PubDocumentosLista",
  props: ["seccion", "subseccion", "publicacion"],

  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("fichas"),
      items: {},
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          nombre: doc.data().nombre,
          apellidos: doc.data().apellidos,
          cargo: doc.data().cargo,
          foto: doc.data().foto,
          url: doc.data().url,
        });
      });
      //console.log(this.items)
    });
  },
};
</script>
