<template>
  <ul class="lista-publicaciones">
    <p v-if="items.length == 0">
      Aún no se han hecho publicaciones
    </p>
    <template v-else>
      <li v-for="i in items" :key="i.key">
        <h3 class="subtitulo" v-if="i.titulo" >{{i.titulo}}</h3>
        <PubDocumentosLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key"/>
        <PubFichasLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key"/>
        <PubArticulosLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key"/>
        <PubFotosLista :seccion="seccion" :subseccion="subseccion" :publicacion="i.key"/>
      </li>
    </template>
  </ul>
</template>

<script>
import firebase from "../firebase";
import PubDocumentosLista from "./PubDocumentosLista.vue"
import PubFichasLista from "./PubFichasLista.vue"
import PubArticulosLista from "./PubArticulosLista.vue"
import PubFotosLista from "./PubFotosLista.vue"

export default {
  name: "PubPublicacionLista",
  props: ["seccion", "subseccion"],
  components: {
    PubDocumentosLista,
    PubFichasLista,
    PubArticulosLista,
    PubFotosLista,
  },
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones"),
      items: {},
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          titulo: doc.data().titulo,
          orden: doc.data().orden,
        });
      });
      //console.log(this.items)
    });
  },
};
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";
  .lista-publicaciones {
    padding-left: 0;
    margin-bottom: 1rem;
    list-style: none;
    p {
      text-align: left;
    }
  }
  .subtitulo {
    font-size: 0.8rem;
    font-weight: 600;
    color: $color-01;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
</style>
